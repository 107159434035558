/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { isEmpty } from "lodash";
import { getFloatVal, priceToFloat, roundToStep } from "../../../functions";

const Price = ({ regularPrice = 0, salesPrice, isDetail }) => {

    /*  if ( isEmpty( salesPrice ) ) {
      return null;
      }*/

    /**
     * Get discount percent.
     *
     * @param {String} regularPrice
     * @param {String} salesPrice
     */
    const discountPercent = (regularPrice, salesPrice) => {
        var salePrice = salesPrice;
        if (isEmpty(salesPrice)) {
            salePrice = regularPrice;
        }

        const formattedRegularPrice = getFloatVal(regularPrice);//parseInt( regularPrice?.substring(1) );
        var extract1;
        if ("string" !== typeof salePrice) {
            extract1 = salePrice;
        } else {
            extract1 = salePrice.split("- ");
            extract1 = extract1[extract1.length - 1];
        }
        const formattedSalesPrice = getFloatVal(extract1);

        const discountPercent = ((formattedRegularPrice - formattedSalesPrice) / formattedSalesPrice) * 100;

        return {
            discountPercent: formattedSalesPrice !== formattedRegularPrice ? `(${roundToStep(discountPercent, 1.0)}%) Descuento` : null,
            strikeThroughClass: formattedSalesPrice < formattedRegularPrice ? "product-regular-price mr-2 line-through text-base text-gray-label font-helvetica-text antialiased" : "",
            regularPriceToShow: priceToFloat(formattedRegularPrice, "$0"),
            salesPriceToShow: priceToFloat(formattedSalesPrice, "$0"),
        };
    };

    const productMeta = discountPercent(regularPrice, salesPrice);
    // var tmp = regularPrice.split(",");//eliminar decimales
    // var regularPriceToShow = tmp[0];
    // var tmp1 = salesPrice.split(",");//eliminar decimales
    // var salesPriceToShow = tmp1[0];

    return (<>
        {isDetail === true ?
            <h4 className="product-price mr-3 flex relative">
                {/* Discounted price */}
                <span className={productMeta?.strikeThroughClass ? productMeta?.strikeThroughClass
                    : "text-base text-black font-helvetica-text font-medium antialiased"}>
                    {productMeta?.salesPriceToShow}
                </span>
                {/* Regular price */}
                <div className="flex">
                    {productMeta?.discountPercent ? <span className="product-price mr-2 text-base text-black font-helvetica-text font-medium antialiased">{productMeta?.regularPriceToShow}</span> : null}
                </div>
            </h4>
            : <h4 className="product-price mr-3">
                {/* Sale price */}
                <span className="text-sm md:text-base text-black font-helvetica-text font-medium tracking-tighter antialiased">{productMeta?.salesPriceToShow}</span>
                {/* Show old regular price if it has a discount*/}
                {productMeta?.discountPercent ? <span className="product-price ml-2 text-sm md:text-base text-black line-through font-helvetica-text font-normal tracking-tighter antialiased">{productMeta?.regularPriceToShow}</span> : null}
            </h4>
        }
    </>
    );
};

export default Price;
