/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { isEmpty } from "lodash";
import { getFloatVal, priceToFloat, roundToStep } from "../../../functions";

const Discount = ({ regularPrice = 0, salesPrice, isDetail }) => {

    /*  if ( isEmpty( salesPrice ) ) {
      return null;
      }*/

    /**
     * Get discount percent.
     *
     * @param {String} regularPrice
     * @param {String} salesPrice
     */
    const discountPercent = (regularPrice, salesPrice, isDetail) => {
        var salePrice = salesPrice;
        if (isEmpty(salesPrice)) {
            salePrice = regularPrice;
        }

        const formattedRegularPrice = getFloatVal(regularPrice);//parseInt( regularPrice?.substring(1) );
        var extract1;
        if ("string" !== typeof salePrice) {
            extract1 = salePrice;
        } else {
            extract1 = salePrice.split("- ");
            extract1 = extract1[extract1.length - 1];
        }
        const formattedSalesPrice = getFloatVal(extract1);

        const discountPercent = (( formattedRegularPrice - formattedSalesPrice ) / formattedSalesPrice) * 100;

        return {
            discountPercent: formattedSalesPrice !== formattedRegularPrice ? `${roundToStep(discountPercent, 1.0)}% Off` : null       
        };
    };

    const productMeta = discountPercent(regularPrice, salesPrice);
    // var tmp = regularPrice.split(",");//eliminar decimales
    // var regularPriceToShow = tmp[0];
    // var tmp1 = salesPrice.split(",");//eliminar decimales
    // var salesPriceToShow = tmp1[0];

    return (<>
        <h4 className={`${productMeta?.discountPercent ? "" : "hidden"} product-price mr-3 flex absolute py-1 px-2 bg-red-cj z-10 top-2 left-2 items-center justify-center rounded-md antialiased`}>
            {/* Regular price */}
            <div className="flex">
                <span className={`${isDetail ? "text-sm" : "text-xs"} product-discount text-white font-helvetica-text font-normal`}>{productMeta?.discountPercent}</span>
            </div>
        </h4>
    </>
    );
};

export default Discount;
