const ThirdParty = process.env.NEXT_PUBLIC_USE_TERCEROS;

export const twq = (action, params) => {
	if (ThirdParty != "FALSE") {
		window.twq("event", action, params);
	}
};
/* Evento para purchase

twq('event', 'tw-pixel_id1-event_id1', {
    value: 200.00,
    currency: 'USD',
    conversion_id: ‘1234abcd’,
    contents: 
    [
       {content_id: 'OT001', content_name: ‘bird seed’, content_price: 50, num_items: 1},
       {content_id: 'OT002', content_name: ‘bird cage, content_price: 150, num_items: 1}
    ]
  });
*/
export const itemsX = (items) => {

	let parseItems = [];
	items?.products?.map(function (item) {
		parseItems.push({
			"content_id": "wc_post_id_" + item.variationId,
			"content_name": item.name, 
			"content_price": item.totalPrice, 
			"num_items": item.qty
		});
	});
	return parseItems;
};